<template>
    <page>
        <page-head>
            <app-button property="success" icon="icon-add" @click="createModal">
                {{ translate('button.Add') }}
            </app-button>
        </page-head>

        <page-body>
            <data-grid
                :data-source="events"
                :columns="columns"
                :action-column-width="150"
                :action-column-text="translate('crm.User.Table.Action')"
            >
                <div slot="actionSlot" slot-scope="list">
                    <app-button
                        v-if="can(permission + '.update')"
                        @click="createModal(list.row.form)"
                        :sm="true"
                        :title="translate('button.Edit')"
                        icon="icon-edit-2"
                        property="primary"
                    />

                    <app-button
                        v-if="can(permission + '.delete')"
                        :sm="true"
                        @click="remove(list.row.id)"
                        :title="translate('button.Delete')"
                        icon="icon-trash-2"
                        property="danger"
                    />
                </div>
                <div slot-scope="list" slot="ratingTemplate">
                    <div>
                        <i v-for="item in list.row.rating" :key="item" class="icon-star3" style="color:yellow"></i>
                        <i v-for="item in (5-list.row.rating)" :key="item" class="icon-star3" style="color:gray"></i>
                    </div>
                </div>
            </data-grid>
        </page-body>

        <modal :id="modalId" size="xs">
            <modal-head>
                <modal-title>{{ currentPage.title }}</modal-title>
            </modal-head>
            <modal-body>
                <form @submit.prevent="save">
                    <grid>
                        
                        <form-group :label="'Başlıq'" name="title">
                            <form-text v-model="form.title"></form-text>
                        </form-group>

                        <form-group :label="'Mətn'" name="text">
                            <form-text-area v-model="form.text"/>
                        </form-group>
                        
                        <form-group :label="'Başlanğıc tarixi'" name="start_date">
                            <!-- <form-date :value.prop="form.start_date" v-model="form.start_date"></form-date> -->
                            <input type="date" :value.prop="form.start_date" v-model="form.start_date" class="form-element"/>
                        </form-group>

                        <form-group :label="'Bitmə tarixi'" name="end_date">
                            <!-- <form-date :value.prop="form.end_date" v-model="form.end_date"></form-date> -->
                            <input type="date" :value.prop="form.end_date" v-model="form.end_date" class="form-element"/>
                        </form-group>

                            <div v-for="(item, index) in form.tags" :key="item" class="mb-4">
                                <grid cols="12">

                                    <!-- <grid-col col="4">
                                        <form-group :label="'Rəng'">
                                            <form-tree-select :options="colors" displayExpr="title" v-model="item.type"/>
                                        </form-group>
                                    </grid-col> -->

                                    <grid-col col="6">
                                        <form-group :label="'Başlıq'">
                                            <form-text v-model="item.title"></form-text>
                                        </form-group>
                                    </grid-col>


                                    <grid-col col="2"  class="flex items-center pr-3">
                                        <app-button type="button" :sm="true" property="primary" v-if="index === 0" @click="addElement('tags')">
                                            <i class="icon-add"></i>
                                        </app-button>
                                        <app-button type="button" :sm="true" property="danger" v-else @click="removeElement(index, 'tags', form)">
                                            <i class="icon-cancel"></i>
                                        </app-button>
                                    </grid-col>

                                </grid>
                            </div>


                        <app-button class="justify-center" property="success" type="submit">
                            {{ translate('button.Save') }}
                        </app-button>
                    </grid>
                </form>
            </modal-body>
        </modal>
    </page>

    
</template>

<script>
/*
 * Import Components
 * */
import {mapActions, mapState} from 'vuex';
// import CoinsUser from "./CoinsUser";
// import TreeSelect from '@riophae/vue-treeselect';
// import { ASYNC_SEARCH } from '@riophae/vue-treeselect';

const formObject = (item = {}) => {
    // let tags = [{type: null, title: null}];
    // if(item.tags != null){
        // tags.push(item.tags)
    // }

    return {
        id: item.id || null,
        title: item.title || null,
        text: item.text || null,
        start_date: item.start_date || null,
        end_date: item.end_date || null,
        departments: item.departments || null,
        tags: item.tags || [ {title: null} ]            
    }
}

const modalId = 'createModal';
const translateKey = 'crm.coin';

export default {
    name: "EventsIndex",
    // components: {TreeSelect},
    data() {
        return {
            translateKey,
            modalId,
            permission: 'event_board',
            colors: [
                {id: 'green', title: 'Yaşıl'},
                {id: 'lime', title: 'Limon'},
                {id: 'yellow', title: 'Sarı'},
                {id: 'red', title: 'Qırmızı'},
                {id: 'orange', title: 'Kök rəngi'},
                {id: 'blue', title: 'Göy'},
                {id: 'cyan', title: 'Mavi'},
                {id: 'teal', title: 'Açıq mavi'},
                {id: 'purple', title: 'Bənövşəyi'},
                {id: 'gray', title: 'Boz'},
            ],
            columns: [
                {
                    caption: 'Title',
                    dataField: 'title',
                    show: true
                },
                // {
                //     caption: 'Tags',
                //     dataField: 'tags',
                //     show: true,
                // },
                {
                    caption: 'Başlanğıc tarixi',
                    dataField: 'start_date',
                    show: true,
                },
                {
                    caption: 'Bitmə tarixi',
                    dataField: 'end_date',
                    show: true,
                },
            ],
            form: formObject()
        }
    },
    computed: {
        ...mapState('EventsStore', ['events']),

    },
    methods: {
        ...mapActions('EventsStore', ['getEvents', 'setEvent', 'deleteEvent']),
        /*
         * Form Create
         * */
       

        formCreate(item = {}) {
            const self = this;
            self.form = formObject(item);
        },
        /*
         * Create Modal
         * */
        createModal(item = {}) {
            this.modal(this.modalId)
            this.resetError();
            this.formCreate(item);
        },
        
        save() {
            this.setEvent(this.form)
            .then(r => {
                if (r) {
                    this.modal(this.modalId);
                    this.getEvents();
                }
            })
        },

        addElement() {
            this.form.tags.push({title: null})
        },
        /*
         * Remove Element
         * */
        removeElement(index) {
            this.form.tags.splice(index, 1);
        },

        /*
         * Remove
         * */
        remove(id) {
            this.alert().then(r => this.deleteEvent(id).then(r => this.getEvents()))
        },
        
    },
    created() {
        this.getEvents();
    },
    
}
</script>

<style scoped>

</style>
